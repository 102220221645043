<template>
  <div>
    <div class="main_back">

      <div class="up_controller up_controller_left">
        <el-button type="primary" class="up_controller_button" disabled>敬请期待</el-button>
        <div style="height: 25px;"></div>
      </div>
      <div style="width: 150px"></div>
      <div class="up_controller up_controller_right">
        <el-button type="danger" class="up_controller_button" @click="gotoOpen">立即开通</el-button>
        <div style="height: 25px;"></div>
      </div>

    </div>
  </div>
</template>

<script>
import oucy from "../../../../util/oucyUtil";
import {userAuth} from '@/service/index.js';
import {localGet, localSet} from "@/store/store";
export default {
  name: "select",
  data() {
    return {
      pageWidth: oucy.pageWidth
    }
  },
  methods: {
    gotoOpen: function () {
      this.$router.replace({
        name:'upgradePay'
      })
    }
  }
}
</script>

<style scoped>
.main_back{
  width: 100%;
  height: 700px;
  background-size: 100% 60%;
  background-image: url("../../../../assets/upgradeBG.png");
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.up_controller{
  width: 510px;
  height: 578px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.up_controller_button{
  width: 75%;
}
.up_controller_left{
  background-size: 100% 100%;
  background-image: url("../../../../assets/up_controller_left.png");
  background-repeat: no-repeat;
}
.up_controller_right{
  background-size: 100% 100%;
  background-image: url("../../../../assets/up_controller_right.png");
  background-repeat: no-repeat;
}
</style>